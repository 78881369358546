<template>
  <!-- div background form -->
  <div class="login-page d-flex justify-center">
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-card v-if="!tokenAuthCode" class="login-form">
        <v-card-title class="login-form-title justify-center">
          <span> {{ $t('auth.login.login') }}</span>
        </v-card-title>
        <!-- Content Card Form -->
        <v-card-text class="px-0">
          <v-text-field
            v-if="authPhone && authEmail"
            v-model="dataForm.user"
            :rules="emailOrPhoneRules"
            :label="$t('auth.login.emailOrPhone')"
            prepend-icon="mdi-account-outline"
          />
          <v-text-field
            v-else-if="authPhone"
            v-model="dataForm.user"
            :rules="phoneRules"
            :label="$t('auth.login.phone')"
            prepend-icon="mdi-phone-outline"
          />
          <v-text-field
            v-else
            v-model="dataForm.user"
            :rules="emailRules"
            :label="$t('auth.login.email')"
            prepend-icon="mdi-email-outline"
          />
          <v-text-field
            v-model="dataForm.password"
            :rules="passwordRules"
            :label="$t('auth.login.password')"
            type="password"
            prepend-icon="mdi-lock-outline"
          />
        </v-card-text>
        <!-- v-card-actions -->
        <v-card-actions>
          <v-row>
            <v-col
              cols="12"
              align="right"
            >
              <small>
                <a
                  href="#"
                  v-text="$t('auth.login.forgotPassword')"
                />
              </small>
            </v-col>
            <v-col cols="12">
              <v-btn
                x-large
                rounded
                :disabled="!valid"
                color="success"
                width="100%"
                @click="login"
              >
                {{ $t('auth.login.login') }}
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              align="center"
            >
              <small v-text="$t('auth.login.signUpUsing')" />
            </v-col>
            <!-- v-col icon mdi -->
            <v-col
              cols="12"
              align="center"
            >
              <v-avatar
                color="#3b5998"
                class="mx-1"
              >
                <v-icon color="white">
                  mdi-facebook
                </v-icon>
              </v-avatar>
              <v-avatar
                color="#1da1f2"
                class="mx-1"
              >
                <v-icon color="white">
                  mdi-twitter
                </v-icon>
              </v-avatar>
              <v-avatar
                color="red"
                class="mx-1"
              >
                <v-icon color="white">
                  mdi-google
                </v-icon>
              </v-avatar>
            </v-col>
            <!-- end v-col icon mdi -->
            <v-col
              cols="12"
              align="center"
            >
              <small v-text="$t('auth.login.signUpUsing')" />
            </v-col>
            <v-col
              cols="12"
              align="center"
            >
              <v-btn
                text
                @click="$router.push('register')"
                v-text="$t('auth.login.signUp')"
              />
            </v-col>
          </v-row>
        </v-card-actions>
        <!-- end v-card-actions -->
      </v-card>
      <v-card v-else class="login-form">
        <v-card-title class="login-form-title justify-center">
          <span>Auth Code</span>
        </v-card-title>
        <!-- Content Card Form -->
        <v-card-text class="px-0">
          <v-text-field
            v-model="dataForm.code"
            :rules="codeRules"
            label="Code"
            prepend-icon="mdi-qrcode"
          />
        </v-card-text>
        <!-- v-card-actions -->
        <v-card-actions>
          <v-row>
            <v-col cols="12">
              <v-btn
                x-large
                rounded
                :disabled="!valid"
                color="success"
                width="100%"
                @click="validate"
              >
                Submit
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              align="center"
            >
              <small style="cursor: pointer" @click="reloadPage" v-text="$t('auth.login.login')" />
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-form>
    <language />
  </div>
</template>

<script>
  import Language from '@/layouts/default/widgets/Language'
  import Repository from '../repository/RepositoryFactory'
  const LoginRepository = Repository.get('login')
  export default {
    name: 'LoginForm',
    components: {
      Language,
    },
    data: () => ({
      apiKey: '',
      authPhone: false,
      authEmail: false,
      dataForm: {
        user: '',
        password: '',
      },
      // error form email and password
      valid: true,
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        (v) => (v && v.length <= 255) || 'Max 255 characters',
      ],
      phoneRules: [
        (v) => !!v || 'Phone is required',
        (v) => /(((\+|)84)|0)(3|5|7|8|9)+([0-9]{8})\b/.test(v) || 'Phone must be valid',
      ],
      emailOrPhoneRules: [
        (v) => !!v || 'Email or Phone is required',
        (v) => (/(((\+|)84)|0)(3|5|7|8|9)+([0-9]{8})\b/.test(v) || /.+@.+\..+/.test(v)) || 'Email or Phone must be valid',
        (v) => (v && v.length <= 255) || 'Max 255 characters',
      ],
      passwordRules: [
        (v) => !!v || 'Password is required',
        (v) => (v && v.length >= 8) || 'Min 8 characters',
        (v) => (v && v.length <= 255) || 'Max 255 characters',
      ],
      tokenAuthCode: '',
      codeRules: [
        (v) => !!v || 'Code is required',
        (v) => (v && v.length <= 255) || 'Max 255 characters',
      ],
      code: '123',
    }),
    async created () {
      const loginRepository = await LoginRepository.getAPIKey()
      const dataResult = loginRepository.data
      // if apiKey.status = true then apiKey of data = apiKey.data
      if (dataResult.status) {
        this.apiKey = dataResult.data.apiKey
        this.authPhone = dataResult.data.authPhone
        this.authEmail = dataResult.data.authEmail
      }
    },
    methods: {
      async login () {
        const valueCheck = await this.$refs.form.validate()
        // if valueCheck = false then stop function login
        if (!valueCheck) {
          return
        }
        // data validate ok
        const dataBody = {
          user: this.dataForm.user,
          password: this.dataForm.password,
        }
        // call API login
        this.showProgressCircular()
        const loginRepository = await LoginRepository.login(dataBody, this.apiKey)
        this.hideProgressCircular()
        // login = API return data
        const login = loginRepository.data
        // if login.status = false then error login.data.message
        if (!login.status) {
          // this.$toast.error(login.data.message)
          if (login.data.length > 0) {
            login.data.forEach(element => {
            const keyObject = Object.keys(element)
            const messageError = `auth.login.notifications.error['${element[keyObject].code}']`
            this.$toast.error(this.$t(messageError))
          });
          } else {
            const messageError = `auth.login.notifications.error['${login.data.code}']`
            this.$toast.error(this.$t(messageError))
          }
          return
        }
        const dataUserLogin = login.data
        this.dataForm.code = ''
        this.tokenAuthCode = dataUserLogin.tokenAuthCode
        if (!this.tokenAuthCode) {
          return this.loginSuccess(dataUserLogin);
        }
      },
      loginSuccess(dataUserLogin) {
         // if login.status = true(success) then localStorage = key 'loggedIn' have value 'OK'
        localStorage.setItem('loggedIn', 'OK')
        // Save data when login success
        // dataUserLogin have data is a Object
        // // convert from style 'object' change into style 'string'
        localStorage.setItem('dataUserLogin', JSON.stringify(dataUserLogin))
        // then success "dang nhap thanh cong"
        // then go page Dashboard
        this.$toast.success(this.$t('auth.login.notifications.success'))
        this.$router.go('/')
      },
      async validate () {
        const valueCheck = await this.$refs.form.validate()
        // if valueCheck = false then stop function register
        if (!valueCheck) {
          return
        }
        // if valueCheck = true
        const dataBody = {
          tokenAuthCode: this.tokenAuthCode,
          code: this.dataForm.code,
        }
        this.showProgressCircular()
        const loginRepository = await LoginRepository.authCode(dataBody, this.apiKey)
        this.hideProgressCircular()
        // login = API return data
        const login = loginRepository.data
        // if login.status = false then error login.data.message
        if (!login.status) {
          this.$toast.error(login.data.message)
          return
        }
        this.loginSuccess(login.data);
      },
      reloadPage() {
        location.reload();
      },
    },
    // Title page
    metaInfo: {
      titleTemplate: 'Login',
    },
  }
</script>

<style lang="scss" scoped>
  // full background desktop 100vh
  $fullScreen: 100vh;
  $backgroundImage: url("https://colorlib.com/etc/lf/Login_v4/images/bg-01.jpg");
  .login-page {
    background-image: $backgroundImage;
    background-size: cover;
    padding: 15px;
    min-height: $fullScreen;
    .login-form {
      max-width: 500px;
      padding: 55px;
      &-title {
        font-size: 39px;
        font-weight: bold;
      }
    }
  }
  // media mobie
  @media only screen and (max-width: 600px) {
    .login-form {
      padding-left: 10px !important;
      padding-right: 10px !important;
    }
  }
</style>
